import React from 'react'

const baseUrl = 'https://handigreg-hosted-content.s3.us-east-2.amazonaws.com/HandiGreg+Assets'

const About = () => {

    return (
        <div className='wrapper'>
            <img id='headshot' src={`${baseUrl}/graduationDayHeadshot369x550.jpg`} />
            <p id='about-section'>
                Greg Smith, CEO HandiGreg
            </p>
        </div>
    )
}

export default About