import React, { useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import './styles/App.css'

import Header from './components/common/Header'
import Hero from './assets/louHero.png'
import Home from './components/pages/Home'
import About from './components/pages/About'
import Projects from './components/pages/Projects'
import Quote from './components/pages/Quote'
import Footer from './components/common/Footer'

function App({ showFooter }) {

  return (
    <div className='container'>
      <Router>
        <Header />
        <img id='hero' src={Hero} alt='Louisville skyline' />
        <Switch>
          <Route exact path='/' component={Home} /> 
          <Route path='/about' component={About} />
          <Route path='/projects' component={Projects} />
          <Route path='/quote' component={Quote} />
          
        </Switch>
        {showFooter && <Footer />}
      </Router>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    showFooter: state.footer.showFooter
  }
}

export default connect(mapStateToProps, {})(App)
