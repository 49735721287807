import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

////////////////////// DEPENDENCIES //////////////////////// 
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'

import { showFooter, hideFooter } from '../../store/actions'

const initialQuoteValues = {
    id: 1,

    /////// TEXT INPUTS ////////
    name: '',
    phone: '',
    email: '',
    projectDescription: ''
}

///////// SHAPE OF THE VALIDATION ERRORS OBJECT ///////////
const initialQuoteErrors = {
    name: '',
    phone: '',
    email: '',
    projectDescription: ''
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ 

const quoteSchema = yup.object().shape({
    name: yup
        .string()
        .required('A name is required'),
    phone: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('A phone number is required'),
    email: yup
        .string()
        .email('A VALID email is required')
        .min(6, 'Email must have at least 6 characters')
        .required('A VALID email is required'),
    projectDescription: yup
        .string()
        .required('A project description is required')
})

const Quote = ({ showFooter, hideFooter }) => {
    const [quoteValues, setQuoteValues] = useState(initialQuoteValues)

    ////////////////// STATE TO KEEP TRACK OF WHETHER SUBMIT BUTTON IS DISABLED //////////////////////
    const [quoteDisabled, setQuoteDisabled] = useState(false)

    ////////////////// STATE TO KEEP TRACK OF VALIDATION ERRORS //////////////////
    const [quoteErrors, setQuoteErrors] = useState(initialQuoteErrors)

    const node = useRef()

    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleClick)

        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])
    
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return
        }

        // outside click
        showFooter()
    }

    const onChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        yup
            .reach(quoteSchema, name)
            .validate(value)

            .then(() => {
                // CLEAR ERROR
                setQuoteErrors({
                    ...quoteErrors,
                    [name]: ''
                })
            })
            .catch(err => {
                // SET THE ERROR IN THE RIGHT PLACe
                setQuoteErrors({
                    ...quoteErrors,
                    [name]: err.errors[0]
                })
            })

        setQuoteValues({
            ...quoteValues,
            [name]: value
        })
    };

    const onSubmit = e => {
        e.preventDefault()
        console.log('submitting')

        const newQuote = {
            id: uuid(),
            name: quoteValues.name,
            email: quoteValues.email,
            projectDescription: quoteValues.projectDescription
        }

        // postQuote(newQuote);
        setQuoteValues(initialQuoteValues)
    }

    ////////// RUN VALIDATION IF FORM VALUES CHANGE /////////////////////
    ////////// AND USE THEM TO ENABLE/DISABLE THE SUBMIT BUTTON ////////////
    useEffect(() => {
        quoteSchema.isValid(quoteValues)
        .then(valid => {
        setQuoteDisabled(!valid);
        })
    }, [quoteValues])
    
    return (
        <div className='wrapper'>
            <form id='quoteForm' onSubmit={onSubmit}>
                <h2 className='title'>Request a service or project</h2>
                <div className='errors'>
                    {/* <h3 id='nameValidationError'>{quoteErrors.name}</h3> */}
                    {/* <h3 id='phoneValidationError'>{quoteErrors.phone}</h3> */}
                    {/* <h3 id='emailValidationError'>{quoteErrors.email}</h3> */}
                    {/* <h3 id='descriptionValidationError'>{quoteErrors.projectDescription}</h3> */}
                </div>
                
                {/* TEXT INPUTS */}
                <h3 id='nameValidationError' className='error-message'>{quoteErrors.name}</h3>
                <input
                    id='name'
                    className='single-line'
                    ref={node}
                    value={quoteValues.name}
                    placeholder='Name'
                    onChange={onChange}
                    onClick={hideFooter}
                    name='name'
                    type='text'
                />
                <h3 id='phoneValidationError' className='error-message'>{quoteErrors.phone}</h3>
                <input
                    id='phone'
                    className='single-line'
                    ref={node}
                    value={quoteValues.phone}
                    placeholder='Phone'
                    onChange={onChange}
                    onClick={hideFooter}
                    name='phone'
                    type='tel'
                />
                <h3 id='emailValidationError' className='error-message'>{quoteErrors.email}</h3>
                <input
                    id='email'
                    className='single-line'
                    ref={node}
                    value={quoteValues.email}
                    placeholder='Email'
                    onChange={onChange}
                    onClick={hideFooter}
                    name='email'
                    type='text'
                />
                <h3 id='descriptionValidationError' className='error-message'>{quoteErrors.projectDescription}</h3>
                <textarea
                    id='projectDescription'
                    ref={node}
                    value={quoteValues.projectDescription}
                    placeholder='Project description...'
                    onChange={onChange}
                    onClick={hideFooter}
                    name='projectDescription'
                    type='textarea'
                    rows='4'
                    cols='50'
                />
                <button id='submit-button' onClick={showFooter}>SUBMIT</button>
            </form>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        showFooter: state.footer.showFooter
    }
}

export default connect(mapStateToProps, {showFooter, hideFooter})(Quote)