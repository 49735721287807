import React from 'react'

import ReqQuote from '../common/ReqQuote'

const Home = () => {
    return (
        <div className='wrapper'>
            <ReqQuote />
        </div>
    )
}

export default Home