import {
    SHOW_FOOTER,
    HIDE_FOOTER
} from '../actions'

const initialState = {
    showFooter: true
}

export const footerReducer = (state = initialState, action) => {
    switch(action.type) {
        case SHOW_FOOTER:
            return {
                ...state,
                showFooter: true
            }
        case HIDE_FOOTER:
            return {
                ...state,
                showFooter: false
            }
        default:
            return state
    }
}