import React from 'react'

const baseUrl = 'https://handigreg-hosted-content.s3.us-east-2.amazonaws.com/HandiGreg+Assets'

const Projects = () => {
    return (
        <div className='wrapper'>

            <div id='first-block' className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/actionShotDemolition.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/staircaseHandrail.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/customDeckConstruction1.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/beforeFloorPaint.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/afterFloorPaint.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/customDeckFinished3.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/customDeckFinished2.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/customDeckFinished.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/customDeckStairecase1.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/midProjectKitchen1.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/finishedKitchen3.jpg`} />

            <div className='text-block'>Lorem Ipsum</div>
            <img src={`${baseUrl}/customDeckPattern2Handrail.jpg`} />
        </div>
        
    )
}

export default Projects