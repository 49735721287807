import React from 'react'
import { useHistory } from 'react-router-dom'

const Header = () => {

  const { push } = useHistory()

  return (
    <div id="header">
      <div onClick={() => {
        push('/')
      }}>Handi Greg</div>
    </div>
  )
}

export default Header