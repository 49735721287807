import axios from 'axios';

export const ADD_NEW_QUOTE = 'ADD_NEW_QUOTE'
export const SHOW_FOOTER = 'SHOW_FOOTER'
export const HIDE_FOOTER = 'HIDE_FOOTER'
export const FETCH_IMAGES_START = 'FETCH_IMAGES_START'
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS'

export const addNewQuote = (newQuote) => {
    return dispatch => {
        dispatch({ type: ADD_NEW_QUOTE, payload: newQuote })

        axios
            .post('http://localhost:2019/quotes/quote')
            .then(res => {
                console.log(res)
            })
            .catch(err => console.log(err))
    }
}

export const showFooter = () => {
    return dispatch => {
        dispatch({ type: SHOW_FOOTER })
    }
}

export const hideFooter = () => {
    return dispatch => {
        dispatch({ type: HIDE_FOOTER })
    }
}