import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Home } from '../../assets/HOME.svg';
import { ReactComponent as About } from '../../assets/ABOUT.svg';
import { ReactComponent as Projects } from '../../assets/PROJECTS.svg';
import { ReactComponent as Quote } from '../../assets/QUOTE.svg';

const Footer = () => {
    return (
        <div id='footer'>
            <Link to='/' >
                <Home className='footerButton extraMargin' />
            </Link>
            <Link to='/about'>
                <About className='footerButton extraMargin' />
            </Link>
            <Link to='/projects'>
                <Projects className='footerButton' />
            </Link>
            <Link to='/quote'>
                <Quote className='footerButton extraMargin' />
            </Link>
        </div>
    )
}

export default Footer