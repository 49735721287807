import {
    ADD_NEW_QUOTE
} from '../actions'

const initialState = {
    quoteList: []
}

export const quoteReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_NEW_QUOTE:
            return {
                ...state,
                quoteList: [...state.quoteList, action.payload]
            }
        default:
            return state
    }
}