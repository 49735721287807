import React from 'react'
import { useHistory } from 'react-router-dom'

const ReqQuote = () => {
    const { push } = useHistory()

    return (
        <div id='request-quote' role='button' onClick={() => push('/quote')} >
            <p>REQUEST A QUOTE TODAY</p>
        </div>
    )
}

export default ReqQuote